import {useEffect, useState} from "react";
import {gql, useApolloClient, useQuery} from "@apollo/client";

const PLAY_TEMP_BOOK_MUTATION = gql`
    mutation playTempBook($gameId:String! $playerId:String! $cards:[CardInput!]!){
        playTempBook(gameId:$gameId playerId:$playerId cards:$cards)
    }
`

const DRAW_DECK_MUTATION = gql`
    mutation drawCard($gameId:String! $playerId:String!) {
        drawCard(gameId:$gameId playerId:$playerId)
    }
`

const DRAW_DISCARD_MUTATION = gql`
    mutation drawDiscard($gameId:String! $playerId:String!) {
        drawDiscard(gameId:$gameId playerId:$playerId)
    }
`

const DISCARD_MUTATION = gql`
    mutation discardCard($gameId:String! $playerId:String! $card:CardInput!){
        discardCard(gameId:$gameId playerId:$playerId card:$card)
    }
`

const SCORE_CARDS_QUERY = gql`
    query scoreCards($cards:[CardInput!]! $gameId:String!) {
        scoreCards(cards:$cards gameId:$gameId)
    }
`

const ADD_CARDS_TO_BOOK = gql`
    mutation playTempCards($gameId:String! $playerId:String! $cards:[CardInput!]! $bookId:String!){
        playTempCards(gameId:$gameId playerId:$playerId cards:$cards bookId:$bookId)
    }
`

const REMOVE_BOOK_CARDS = gql`
    mutation removeTempCards($gameId:String! $playerId:String! $selectedCards:[String!]!) {
        removeTempCards(gameId:$gameId playerId:$playerId cardIds:$selectedCards)
    }
`

const CAN_PLAY_CARDS_ON_BOOK = gql`
    query canAddCardsToBook($gameId:String! $playerId:String! $selectedCards:[String!]! $bookId:String!) {
        canAddCardsToBook(gameId:$gameId playerId:$playerId cardIds:$selectedCards bookId:$bookId)
    }
`

function MyBookInfo({
                        cards,
                        meldId: bookId,
                        addCards,
                        selectedHandCards,
                        selectedBookCards,
                        selectCardFunc,
                        gameId,
                        playerId
                    }) {
    const selectedCards = (selectedBookCards && selectedBookCards.length === 0) ? selectedHandCards : []
    const {data} = useQuery(CAN_PLAY_CARDS_ON_BOOK, {
        fetchPolicy: "no-cache",
        variables: {gameId, bookId, selectedCards, playerId}
    })
    let canAddCardsToBook = false
    if (data && data.canAddCardsToBook) {
        canAddCardsToBook = selectedBookCards.length === 0
    }
    return (
        <div id="bookinfowrapper" style={{display: "flex", flexDirection: "column"}}>
            <span className={"meldaddcards"} id={`add_cards_button_${bookId}`} onClick={() => {
                addCards()
            }} style={{
                visibility: canAddCardsToBook ? "visible" : "hidden",
                cursor: "pointer"
            }}>Add Cards</span>
            <div id="bookinfo"
                 style={{
                     gridTemplateColumns: cards.length > 1 ? `repeat(${cards.length - 1}, 40px) 120px` : "120px",
                     gridTemplateRows: '1.75in'
                 }}>
                {cards.map((it, index) => (
                    <div key={index}
                         className={`cardsprite halfheightcard ${it.suit}_${it.number}${selectedBookCards.includes(it.id) ? " stockitem_selected" : ""}`}
                         onClick={() => {
                             selectCardFunc(it.id)
                         }}/>
                ))}
            </div>
        </div>
    )

}

function OpponentBookInfo({
                              cards,
                              meldId: bookId,
                          }) {
    return (
        <div id="bookinfowrapper" style={{display: "flex", flexDirection: "column"}}>
            <span className={"meldaddcards"} id={`add_cards_button_${bookId}`} style={{
                visibility: "hidden",
                cursor: "pointer"
            }}>Add Cards</span>
            <div id="bookinfo"
                 style={{
                     gridTemplateColumns: cards.length > 1 ? `repeat(${cards.length - 1}, 40px) 120px` : "120px",
                     gridTemplateRows: '1.75in'
                 }}>
                {cards.map((it, index) => (
                    <div key={index}
                         className={`cardsprite halfheightcard ${it.suit}_${it.number}`}/>
                ))}
            </div>
        </div>
    )

}

const ROUND_ENUM_TO_TEXT = {
    "ONE": "3s",
    "TWO": "4s",
    "THREE": "5s",
    "FOUR": "6s",
    "FIVE": "7s",
    "SIX": "8s",
    "SEVEN": "9s",
    "EIGHT": "10s",
    "NINE": "Js",
    "TEN": "Qs",
    "ELEVEN": "Ks",
}

function MyBooks({
                     displayBooks,
                     selectedHandCards,
                     selectedBookCards,
                     selectCardFunc,
                     playCardsFunc,
                     gameId,
                     playerId
                 }) {
    return (
        <div id={"mybooks"}>
            {displayBooks.map(it => <MyBookInfo selectedHandCards={selectedHandCards}
                                                selectedBookCards={selectedBookCards}
                                                cards={it.cards} key={it.id}
                                                meldId={it.id}
                                                addCards={() => playCardsFunc(it.id)}
                                                gameId={gameId} playerId={playerId}
                                                selectCardFunc={selectCardFunc}/>)}
        </div>
    )
}

function OpponentBooks({displayBooks, id}) {
    return (
        <div id={`opponentbooks_${id}`} className="opponentbooks">
            {displayBooks.map(it => <OpponentBookInfo
                cards={it.cards} key={it.id}
                meldId={it.id}/>)
            }
        </div>
    )
}

function TeamInfo({
                      teamData,
                      newMeldFunc,
                      removeCardsFunc,
                      playCardsFunc,
                      selectedCards,
                      selectCardFunc,
                      gameId,
                      playerId,
                  }) {
    const displayBooks = teamData.playedBooks
    const displayBooksCardIds = displayBooks.flatMap(it => it.cards.map(it => it.id))
    const bookCardsSelected = selectedCards.length !== 0 && selectedCards.every(it => displayBooksCardIds.includes(it))
    const handCardsSelected = selectedCards.length !== 0 && selectedCards.every(it => !displayBooksCardIds.includes(it))
    const selectedBookCards = selectedCards.length !== 0 ? selectedCards.filter(it => displayBooksCardIds.includes(it)) : []
    const selectedHandCards = selectedCards.length !== 0 ? selectedCards.filter(it => !displayBooksCardIds.includes(it)) : []

    return (
        <div id={"mybooks_wrap"}>
            <div id={"mybooks_labels"} style={{fontWeight: "bold"}}>
                <span style={{marginLeft: "10px"}}>
                    <span className={"to_translate"}>{teamData.name} {teamData.score}</span>
                </span>
                <span className={"meldnewmeld meld_rank"} id={"new_meld_button"} onClick={() => {
                    newMeldFunc()
                }} style={{
                    display: handCardsSelected ? "inherit" : "none"
                }}>New Book</span>
                <span className={"meldnewmeld meld_rank"} id={"remove_cards_button"} onClick={() => {
                    removeCardsFunc()
                }} style={{
                    cursor: "pointer",
                    display: bookCardsSelected ? "inherit" : "none"
                }}>Remove Cards</span>
                <span className={"meldnewmeld meld_rank"} id={"place_holder"} style={{
                    visibility: "hidden"
                }}>hidden</span>
            </div>
            <div id={"mybooks_outer"} className={"whiteblock"}>
                <MyBooks displayBooks={displayBooks} selectedBookCards={selectedBookCards}
                         selectedHandCards={selectedHandCards} selectCardFunc={selectCardFunc}
                         playCardsFunc={playCardsFunc} gameId={gameId} playerId={playerId}/>
            </div>
        </div>
    )
    // return (
    //     <div id={"mymelds_wrap"}>
    //         <div id={"mymelds_info"}>
    //             <h3 id={"mymelds_label"} style={{marginLeft: "10px"}}>
    //                 <span className={"to_translate"}>Your books</span>
    //             </h3>
    //             <div className={"teaminfo"} id={teamId}>
    //                 <div id={`${teamId}_label`} className={"playertablename"} style={{color: "#ff0000"}}>
    //                     <span>{teamData.teamName || teamData.teamId}</span>
    //                 </div>
    //                 <div className={"teamnames"} id={`teamnames_${teamId}`}>
    //                     <PlayerInfo teamId={teamId} playerInfo={teamData}/>
    //                 </div>
    //             </div>
    //             <div id={`scores_${teamId}`} className={"haf_scores"}>
    //             <span className={"haf_total_score_label"} id={`game_score_${teamId}`}>
    //                 <span className={"to_translate"}>Game score</span>:
    //             </span>
    //                 <span className={"haf_total_score"} id={"total_score_2"}>{teamData.score}</span>
    //             </div>
    //         </div>
    //         <div id={`playermelds_${teamId}`} className={"playermelds whiteblock"}>
    //             <span id={"new_meld"} className={"meld new_meld_button"} style={{padding: "5px"}}>
    //                 <div className={"meld_rank"} style={{cursor: enabled ? "pointer" : "default"}}>
    //                     <span className={"meldnewmeld"} id={"new_meld_button"} onClick={() => {
    //                         newMeldFunc()
    //                     }}>New Book</span>
    //                 </div>
    //                 <div className={"meld_rank"} style={{
    //                     cursor: enabled ? "pointer" : "default",
    //                     visibility: bookCardsSelected ? "visible" : "hidden"
    //                 }}>
    //                     <span className={"meldnewmeld"} id={"new_meld_button"} onClick={() => {
    //                         removeCardsFunc()
    //                     }}>Remove Cards</span>
    //                 </div>
    //             </span>
    //             {displayBooks.map(it => <BookInfo selectedCards={selectedCards} cards={it.cards} key={it.id}
    //                                               meldStats={it.meldStats} meldId={it.id}
    //                                               onClick={() => playCardsFunc(it.id)}
    //                                               selectCardFunc={selectCardFunc}/>)}
    //         </div>
    //     </div>
    // )
}

function PlayerHand({hand, selectCardFunc, selectedCards, computeScoreFunc}) {
    const [computedScore, setComputedScore] = useState(0)
    useEffect(() => {
        computeScoreFunc(hand.filter(it => selectedCards.includes(it.id)).map(it => ({
            number: it.number,
            suit: it.suit,
            id: it.id
        }))).then(({data}) => {
            console.log({data})
            setComputedScore(data.scoreCards)
        })
    })

    return (<div id={"myhand_wrap"}>
        <div id={"myhand_labels"} style={{fontWeight: "bold"}}>
						<span style={{marginLeft: "10px"}}>
							<span className={"to_translate"}>Your hand</span>
						</span>
            <div id={"myhand_selscore_box"}>
                <span>Selection score:</span>
                <span id={"myhand_selscore_counter"}>{computedScore}</span>
            </div>
            <div style={{visibility: "hidden"}}/>
        </div>
        <div id={"myhand_outer"} className={"whiteblock"}>
            <div id={"myhand"} style={{position: "relative", display: "flex"}}>
                {hand.map((it, index) =>
                    (<div key={it.id}
                          className={`stockitem${selectedCards.includes(it.id) ? " stockitem_selected" : ""}`}
                          style={{
                              position: "relative",
                              top: "0px",
                              left: `${index * 40}px`,
                              width: "0px",
                              height: "184px",
                              zIndex: index + 2,
                              opacity: 1
                          }}
                          onClick={() => {
                              selectCardFunc(it.id)
                          }}>
                        <div className={`cardsprite halfheightcard ${it.suit}_${it.number}`}/>
                    </div>)
                )}
            </div>
        </div>
    </div>)
}

function OpponentInfo({opponentData}) {
    return (<div style={{display: "flex", flexDirection: "row", overflow: "auto"}}>
        {opponentData.map((it, index) => {
            return (
                <div id={"opponentbooks_wrap"}>
                    <div id={"opponentbooks_labels"} style={{fontWeight: "bold"}}>
                    <span style={{marginLeft: "10px"}}>
                        <span className={"to_translate"}>{it.name} {it.score}</span>
                    </span>
                        <span className={"meldnewmeld meld_rank"} id={"place_holder"} style={{
                            visibility: "hidden"
                        }}>hidden</span>
                    </div>
                    <div id={"opponentbooks_outer"} className={"whiteblock"}>
                        <OpponentBooks displayBooks={it.playedBooks} id={index}/>
                    </div>
                </div>
            )
        })}
    </div>)
}

function CommonArea({
                        gameStateInfo: {
                            roundNumber,
                            deckSize,
                            topCard,
                            discardSize,
                            goingOutPlayerName,
                            activePlayerInfo: {name: currentPlayer}
                        },
                        drawCardFunc,
                        drawDiscardFunc,
                        canDiscard,
                        discardFunc,
                        canDraw,
                        enabled,
                    }) {
    return (
        <div id={"tablecards"} className={"whiteblock"}>
            <div id={"table_info_1"} className={"table_info_block"}>
                <div id={"gameheader"}>
                    <span>👑👑👑👑👑</span>
                </div>
                <div className={"round_info"}>
                    <div id={"round_info"}>
                        <span id={"current_player_label"} className={"round_info_text"}>Player: </span>
                        <div id={"current_player"}>{currentPlayer}</div>
                        <br/>
                        <span id={"round_number_label"} className={"round_info_text"}>Round: </span>
                        <div id={"round_number"}>{ROUND_ENUM_TO_TEXT[roundNumber]}</div>
                        <br/>
                        {!!goingOutPlayerName &&
                            <div>
                                <span id={"round_minimum_label"} className={"round_info_text"}>Gone Out: </span>
                                <span id={"round_minimum"}>{goingOutPlayerName}</span><
                            /div>
                        }
                    </div>
                    {/*<div id={"target_contract"} className={"target_contract"}>
                            <div>
							<span style={{marginLeft: "10px"}}>
								<span className={"to_translate"}>Target contract</span>:
							</span>
                                <div id={"target_contractbar"} className={"contractbar contract2"}>
                                    <span className={"contractsection contractsection_any"}/>
                                    <span className={"contractsection contractsection_any"}/>
                                </div>
                            </div>
                        </div>*/}
                </div>
            </div>
            {/*<div id={"table_info_2"} className={"table_info_block"}>*/}
            {/*    <div id={"round_to_go_box"}*/}
            {/*         style={{display: "inline-block", visibility: isOpening ? "visible" : "hidden"}}>*/}
            {/*        <span id={"round_to_go_label"} className={"round_info_text"}>Still needed to open: </span>*/}
            {/*        <div*/}
            {/*            id={"round_to_go"}>{selectedScore < minOpeningScore ? minOpeningScore - selectedScore : 0}</div>*/}
            {/*    </div>*/}
            {/*    <div id={"haf_undo_button"} onClick={undoFunc}*/}
            {/*         style={{display: "inline-block", visibility: isOpening ? "visible" : "hidden"}}>Undo*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div id={"table_info_3"} className={"table_info_block"}>
                <div id={"card_piles"} className={"card_piles"}>
                    <div id={"stock_wrap"} className={"carddeck_wrap"}>
                        <div id={"stocklabel"} className={"cardcounter"}>
                            <span className={"to_translate"}>Draw</span>
                        </div>
                        <div id={"stock_hl"} className={`${enabled && canDraw && "pulsing_bg"}`}>
                            <div id={"stock_clip"} className={"carddeck halfheightcard_wrap"}>
                                <div id={"stock"} className={"cardsprite card_back halfheightcard"}
                                     onClick={() => {
                                         if (enabled && canDraw) {
                                             drawCardFunc()
                                         }
                                     }} style={{cursor: canDraw && enabled ? "pointer" : "default"}}/>
                            </div>
                        </div>
                        <div id={"stockcounter"} className={"cardcounter"}>{deckSize}</div>
                    </div>
                    <div id={"discard_wrap"} className={"carddeck_wrap"}>
                        <div id={"discardlabel"} className={"cardcounter"}>
                            <span className={"to_translate"}>Discard</span>
                        </div>
                        <div id={"discard_hl"} className={`${enabled && (canDiscard || canDraw) && "pulsing_bg"}`}>
                            <div id={"stock_clip"} className={"carddeck halfheightcard_wrap"}>
                                <div id={"discarded_card"}
                                     className={`${!!topCard ? "cardsprite" : "blankcard"} ${!!topCard && `${topCard.suit}_${topCard.number}`} halfheightcard`}
                                     onClick={(() => {
                                         if (enabled && canDraw) {
                                             drawDiscardFunc()
                                         } else if (enabled && canDiscard) {
                                             discardFunc()
                                         }
                                     })}
                                     style={{cursor: enabled && (canDraw || canDiscard) ? "pointer" : "default"}}/>
                            </div>
                        </div>
                        <div id={"stockcounter"} className={"cardcounter"}>{discardSize}</div>
                    </div>
                    {/*<div id={"discard_wrap"} className={"carddeck_wrap"}>*/}
                    {/*    <div id={"stocklabel"} className={"cardcounter"}>*/}
                    {/*        <span className={"to_translate"}>Discard</span>*/}
                    {/*    </div>*/}
                    {/*    <div id={"discard_hl"}*/}
                    {/*         className={`${enabled && (canDraw || canDiscard) && "pulsing_bg"}`}>*/}
                    {/*        <div id={"discard_clip"} className={"carddeck"}>*/}
                    {/*            <div id={"discarded_card"}*/}
                    {/*                 className={`${!!topCard ? "cardsprite" : "blankcard"} ${!!topCard && `${topCard.suit}_${topCard.number}`} halfheightcard`}*/}
                    {/*                 onClick={(() => {*/}
                    {/*                     if (enabled && canDraw) {*/}
                    {/*                         drawDiscardFunc()*/}
                    {/*                     } else if (enabled && canDiscard) {*/}
                    {/*                         discardFunc()*/}
                    {/*                     }*/}
                    {/*                 })}*/}
                    {/*                 style={{cursor: enabled && (canDraw || canDiscard) ? "pointer" : "default"}}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <br/>*/}
                    {/*    <div id={"discardcounter"} className={"cardcounter"}>{discardSize}</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

function WholeTable({gameStateInfo, gameId}) {
    const client = useApolloClient()
    const myPlayerData = gameStateInfo.playerData.filter(it => !!it.id)[0]
    const isItMyTurn = !!gameStateInfo.activePlayerInfo.id
    const canDraw = !gameStateInfo.playerHasDrawn
    const [selectedCards, setSelectedCards] = useState([])
    const selectCard = (id) => {
        if (selectedCards.includes(id)) {
            setSelectedCards(selectedCards.filter(it => it !== id))
        } else {
            setSelectedCards(selectedCards.concat(id))
        }
    }
    const playerId = myPlayerData.id
    const otherPlayerData = gameStateInfo.playerData.filter(it => myPlayerData.id !== it.id)
    const displayHand = myPlayerData.hand
    const newMeld = () => {
        const cards = selectedCards.map(id => displayHand.filter(it => it.id === id)).flat()
        const inputCards = cards.map(it => {
            return {suit: it.suit, number: it.number, id: it.id}
        })
        client.mutate({
            mutation: PLAY_TEMP_BOOK_MUTATION,
            variables: {
                gameId,
                playerId,
                cards: inputCards
            }
        }).then(({data}) => {
            console.log(data)
            if (data.playTempBook === "success") setSelectedCards([])
        })
    }

    const removeCards = () => {
        client.mutate({
            mutation: REMOVE_BOOK_CARDS,
            variables: {
                gameId,
                playerId,
                selectedCards,
            }
        }).then(({data}) => {
            console.log(data)
            if (data.removeTempCards === "success") {
                setSelectedCards([])
            }
        })
    }

    const drawCard = () => {
        client.mutate({mutation: DRAW_DECK_MUTATION, variables: {gameId, playerId}}).then(({data}) => {
            console.log(data.draw)
        })
    }

    const drawDiscard = () => {
        client.mutate({mutation: DRAW_DISCARD_MUTATION, variables: {gameId, playerId}}).then(({data}) => {
            console.log(data.drawDiscard)
        })
    }

    const discard = () => {
        if (selectedCards.length !== 1) {
            console.log("error")
        } else {
            const card = displayHand.filter(it => selectedCards[0] === it.id)[0]
            setSelectedCards([])
            console.log(card)
            client.mutate({
                mutation: DISCARD_MUTATION,
                variables: {gameId, playerId, card: {number: card.number, suit: card.suit, id: card.id}}
            }).then(({data}) => {
                console.log(data.discard)
            })
        }
    }

    const computeScore = (cards) => {
        console.log({query: SCORE_CARDS_QUERY, variables: {cards, gameId}})
        return client.query({query: SCORE_CARDS_QUERY, variables: {cards, gameId}})
    }


    const playCards = (bookId) => {
        if (selectedCards.length !== 0) {
            const cards = displayHand.filter(card => selectedCards.includes(card.id)).map(it => ({
                suit: it.suit,
                number: it.number,
                id: it.id
            }))
            client.mutate({
                mutation: ADD_CARDS_TO_BOOK,
                variables: {gameId, playerId, cards, bookId}
            }).then(({data}) => {
                console.log(data)
                if (data.playTempCards !== "success") {
                    //alert user
                } else {
                    setSelectedCards([])
                }
            })
        }
    }
    return (
        <div id={"wholetable"}>
            <CommonArea enabled={isItMyTurn} gameStateInfo={gameStateInfo} drawCardFunc={drawCard}
                        discardFunc={discard} canDraw={canDraw}
                        drawDiscardFunc={drawDiscard}
                        canDiscard={!canDraw && selectedCards.length === 1}/>
            <PlayerHand hand={displayHand} selectCardFunc={selectCard} selectedCards={selectedCards}
                        computeScoreFunc={computeScore}/>
            <TeamInfo enabled={isItMyTurn && !canDraw} teamData={myPlayerData} newMeldFunc={newMeld}
                      removeCardsFunc={removeCards} gameId={gameId} playerId={myPlayerData.id}
                      playCardsFunc={playCards} selectedCards={selectedCards} selectCardFunc={selectCard}/>
            <OpponentInfo opponentData={otherPlayerData}/>
            <div id={"melds_placeholder"} style={{display: "none"}}/>
        </div>
    )
}

export default WholeTable
